import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../inc/Navbar";
import Homesample from "../images/homesample.jpg"
import Labtest from "../images/lab.jpg"
import Doctorsall from "../images/doctorpage.jpg"
import Franchisee from "../images/franchisee.jpg";
import Career from "../images/CAREER.jpg";






import "./Services.css";

import { Link } from 'react-router-dom';


function Services() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };

    return (
        <div>
            <Navbar />

            <div className="animat">
                <Slider {...settings}>
                    <div>

                        <Link to="/home-sample-colllection">
                            <img src={Homesample} className="card-img-top mb-1" alt="questdiagnostics" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "darkBlue" }} className="card-text mb-0"> <small>BOOK A HOME SAMPLE</small></p>
                                <Link to="/home-sample-colllection">
                                    <p className="card-text" style={{ color: "grey" }}> <small> <span>CLICK NOW</span></small></p> </Link>
                            </center>
                        </div>
                    </div>

                    <div>



                        <Link to="/labtest">
                            <img src={Labtest} className="card-img-top mb-1" alt="questdiagnostics" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "darkBlue" }} className="card-text mb-0"><small>FIND A TEST</small></p>
                                <Link to="/labtest">
                                    <p className="card-text" style={{ color: "grey" }}> <small> <span>CLICK NOW</span></small></p> </Link>
                            </center>
                        </div>
                    </div>


                    <div>


                        <Link to="/find-doctor">
                            <img src={Doctorsall} className="card-img-top mb-2" alt="questdiagnostics" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "darkBlue" }} className="card-text mb-0"><small>BOOK AN APPOINTMENT</small></p>
                                <Link to="/find-doctor">
                                    <p className="card-text" style={{ color: "grey" }}> <small> <span>CLICK NOW</span></small></p> </Link>
                            </center>
                        </div>

                    </div>

                    <div>


                        <Link to="#">
                            <img src={Franchisee} className="card-img-top mb-2" alt="questdiagnostics" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "darkBlue" }} className="card-text mb-0"><small>FRANCHISEE</small></p>
                                <Link to="#">
                                    <p className="card-text" style={{ color: "grey" }}> <small> <span>CLICK NOW</span></small></p> </Link>
                            </center>
                        </div>

                    </div>
                    <div>


                        <Link to="#">
                            <img src={Career} className="card-img-top mb-2" alt="questdiagnostics" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "darkBlue" }} className="card-text mb-0"><small>CAREER</small></p>
                                <Link to="#">
                                    <p className="card-text" style={{ color: "grey" }}> <small> <span>CLICK NOW</span></small></p> </Link>
                            </center>
                        </div>

                    </div>





                    <div>


                    </div>


                </Slider>
            </div>

        </div>
    )
}



export default Services
