import React from 'react'
import Navbar from '../inc/Navbar'
import AlcoholPackage from './AlcoholPackage'
import {Link} from "react-router-dom";

import GeneralP from './GeneralP'
import DiabeticP from './DiabeticP';

function Healthpage() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            
            
            <div className='colorbackground'>
            <br />
            <br />
                <div className='album py-2'>
                    <div className="container mt-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{color:"white"}} to="/" >Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/labtest" style={{color:"white"}} >Labtest</Link></li>
                                <li style={{color:"white"}} className="breadcrumb-item active" aria-current="page">Health Package</li>
                            </ol>
                        </nav> 
                        <br />
                    </div>
                </div>
            </div>
<br/>
            <div className='album py-1'>
                <div className="container">
                    <h4 style={{ color: "darkblue" }}><b style={{ color: 'grey' }}>Popular Categories</b>   Test Book</h4>
                </div>
            </div>
            <div className='album py-1'>
                <div className="container">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">GENERAL PACKAGE</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">ALCOHOL IMPACT</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">DIABETIC PACKAGE</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <br />
                            <GeneralP />
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <br />

                            <AlcoholPackage />

                        </div>
                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            <br />
                            <DiabeticP />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Healthpage