import React from 'react'
import {Link} from "react-router-dom";
import Doctorconsult from "../images/doctor-consult.jpg";
import Pathologydepartment from "../images/pathology-department.jpg"
import reception from "../images/patient-image.jpg";
import questdiagnostics from "../images/MAIN_BANNER.jpg"
function Slider() {
  return (
    <div>

<div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <Link to="/find-doctor">
      <div className='borderr'>
      <img src={questdiagnostics} className="d-block w-100" alt="quest_diagnostics"/>
      </div>
      </Link>
    </div>
    <div className="carousel-item"> 
    <div className='borderr'>
      <img src={reception} className="d-block w-100" alt="quest_diagnostics"/>    
      </div>
    </div>
    <div className="carousel-item"> 
    <Link to="/find-doctor">
    <div className='borderr'>
      <img src={Doctorconsult} className="d-block w-100" alt="quest_diagnostics"/>   
      </div> 
      </Link>
    </div>

    <div className="carousel-item">
      <Link to="/labtest">
      <div className='borderr'>
      <img src={Pathologydepartment} className="d-block w-100" alt="quest_diagnostics"/>
      </div>
      </Link>
    </div>
    
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Slider