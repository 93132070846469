import React, { useState, useEffect }  from 'react';

import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'

function Xray() {
   const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(21);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [slide, setSlide] = useState([])
      const navigate = useNavigate()
  
      const slideCollectionRef = collection(db, "Xray-Department-Photo");
      useEffect(() => {
  
          const getSlide = async () => {
              const data = await getDocs(slideCollectionRef);
              setSlide(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getSlide()
      }, [])
  return (
    <div>

{slide.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((questdiagnostics) => { return (
       
       
                       
                            <div className="card-body">
                                   
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          
      




    
    
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={questdiagnostics.slider1} className="d-block w-100" alt="quest_diagnostics"/>
        </div>
        <div className="carousel-item">
          <img src={questdiagnostics.slider2} className="d-block w-100" alt="Best diagnostics center in krishnagar"/>
        </div>
       
        <div className="carousel-item">
          <img src={questdiagnostics.slider3} className="d-block w-100" alt="Best diagnostics center in krishnagar"/>
        </div>
      
    
       
      </div>
     
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  
                                
                               
                              
                              
                               

                               
                    
                              
                            </div>
                           
                                )})} 
                            
                   
      
    </div>
  )
}

export default Xray
