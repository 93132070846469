import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Form, Alert, InputGroup, Button, ButtonGroup, Card } from "react-bootstrap";
export const Contactdata = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_44cmdfe', 'template_vxarmoj', form.current, {
        publicKey: 'IFnycGNBa7BCawZY8',
      })

      .then(
        () => {
          console.log();
          alert("your massage sent successfuly");
        },

        (error) => {
          console.log('FAILED...', error);
        },
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className='album py-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <Card body shadow>
                <center>
                <div className="textcolor">
                <h5 className='mb-3'>BOOK A HOME VISIT NOW</h5>
                </div>
                </center>
                <div className='card'>

                  <input type="name" class="form-control mb-3" id="exampleFormControlInput1" name="from_name" placeholder="Enter Patient / Prescribe Name" required />

                  <input type="mobile" className="form-control mb-3" id="exampleFormControlInput1" name="from_mobile" placeholder="Mobile number" required />
                  <input type="city" class="form-control mb-3" id="exampleFormControlInput1" name="from_city" placeholder="City" required />
                  <input type="labtestname" class="form-control mb-3" id="exampleFormControlInput1" name="from_labtestname" placeholder="Test Name" required />



                  <center>



                    <input type="submit" className='btn btn-secondary mb-2' value="Submit From" required />
                  </center>
                </div>
            </Card>
          </div>
        </div>
      </div>
    </div>

    </form >
  );
};