import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import { db } from '../../firebase'
import Navbar from '../inc/Navbar';
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";

import ScrollToTop from "react-scroll-to-top";





function Doctor() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(2500);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [labtest, setLabtest] = useState([])
    const navigate = useNavigate()

    const labtestCollectionRef = collection(db, "AllDoctor");
    useEffect(() => {

        const getLabtest = async () => {
            const data = await getDocs(labtestCollectionRef);
            setLabtest(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getLabtest()
    }, [])
    return (

        <>
            <Navbar />


            <br />
            <br />
            <ScrollToTop smooth top="100" color="light" />

            <br />
            <br />

            <div className='diagnostics'>
                <div className="album py-1 mt-2">

                    <div className="container">

                        <div className="row">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li style={{ color: "grey" }} className="breadcrumb-item"><Link style={{ color: "grey" }} to="/" >  Home</Link></li>

                                    <li style={{ color: "grey" }} className="breadcrumb-item"><Link style={{ color: "grey" }} to="/contact-us" href="#">Contact</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Find a Test</li>

                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>


            <div className="album py-3">

                <div className="container">

                    <div className="row">

                        <div className="col-md-6">

                            <div className="card-body">
                                <input type="text" className="form-control" placeholder="Search" name="location"
                                    onChange={(e) => {
                                        setsearchTerm(e.target.value);
                                    }}
                                />
                                <div className="d-flex justify-content-between align-items-center">




                                </div>


                            </div>


                        </div>

                    </div>

                </div>
            </div>


            <div className="album py-4">

                <div className="container">

                    <div className="row">
                        {labtest.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase())


                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((questdiagnostics) => {
                            return (
                                <div className="col-md-4 mt-8" >
                                    <div className="card cardnew mb-2">
                                        <img className="card-img-top" src={questdiagnostics.img} alt={questdiagnostics.name} />
                                        <div className="card-body">
                                            <div className="textcolor">
                                                <h3 className="card-text"> <b>{questdiagnostics.name}</b></h3>
                                            </div>
                                            <p style={{ color: "grey" }} className="card-text mb-1"><small>{questdiagnostics.des}</small></p>
                                            <p style={{ color: "grey" }} className="card-text mb-2"><small>{questdiagnostics.degree}</small></p>
                                            <h6 className="card-text" style={{ color: "grey" }}>Reg.{questdiagnostics.reg} </h6>
                                            <h5 style={{ color: "grey" }} className="card-text mb-3"><small>{questdiagnostics.des2}</small></h5>
                                            <h4 className="blink" style={{ color: "maroon" }}>{questdiagnostics.date} </h4>
                                            <h4 className="blink" style={{ color: "darkBlue" }}>{questdiagnostics.upcoming} </h4>
                                            <div className='colort'>
                                                <h5 className="card-text mb-3">  {questdiagnostics.fees}</h5>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                            <a href='tel: +91 9332701212' className='buttoncalldoctor me-2 mb-2'>Book Appointment</a>
                                            <ReactWhatsapp number="+91 9332701212" className="buttoncalldoctor   me-2 mb-2" message="I am Interested"> WhatsApp </ReactWhatsapp>
                                        </div>
                                          
                                            <div className="d-flex justify-content-start align-items-center mb-2">
                                                <a className='btn-grad20' href={questdiagnostics.link} target='_black' rel="noopener noreferrer" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                                                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                                                </svg> {questdiagnostics.text}</a>
                                            </div>


                                        </div>

                                    </div>

                                </div>

                            )
                        })}
                    </div>

                </div>
            </div>

        </>

    );
}

export default Doctor;



