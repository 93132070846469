import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import Xray from "../images/xray.jpeg"
import usg from "../images/usg.jpg";
import Ctscan from "../images/ctscan.jpg";
import Mri from "../images/mri.jpg";
import opgnew from "../images/opg.jpg";
import ScrollToTop from "react-scroll-to-top";
import Imaging from './Imaging';
function Radiology() {
    return (
        <div>
            <Navbar />

            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="light" />
            <div className='diagnostics'>

                <div className="album py-0 mt-3">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>

                                <li className="breadcrumb-item active" aria-current="page">Radiology & Imaging <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg> </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-2">
                <div className="container">
                    <div className="row">

                        <div className="col-md-3 mt-3">
                        <Link to="/xray">
                            <div class="card">
                                <img src={Xray} className="card-img-top mb-3" alt="xray" />
                                <div className="card-body">
                                    <center>
                                        <h5 style={{ color: "darkblue" }} className="card-title mb-1">X-Ray</h5>
                                        <p style={{ color: "darkBlue" }} className="card-text">Learn More <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="13" height="13" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                        </svg></p>
                                    </center>

                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="col-md-3 mt-3">
                            <Link to="/usg">
                                <div class="card">
                                    <img src={usg} className="card-img-top mb-3" alt="usg" />
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkBlue" }} className="card-title mb-0">USG</h5>
                                            <p style={{ color: "darkBlue" }} className="card-text">Learn More <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="13" height="13" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                            </svg></p>
                                        </center>

                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 mt-3">
                            <Link to="/ct-scan">
                                <div class="card">

                                    <img src={Ctscan} className="card-img-top mb-3" alt="Ctscan" />
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkBlue" }} className="card-title mb-0">CT-Scan</h5>
                                            <p style={{ color: "darkBlue" }} className="card-text">Learn More <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="13" height="13" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                            </svg></p>
                                        </center>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        <div className="col-md-3 mt-3">
                            <Link to="/mri">
                                <div class="card">
                                    <img src={Mri} className="card-img-top mb-3" alt="mri" />
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkBlue" }} className="card-title mb-0">MRI</h5>
                                            <p style={{ color: "darkBlue" }} className="card-text">Learn More <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="13" height="13" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                            </svg></p>
                                        </center>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        <div className="col-md-3 mt-3">
                            <Link to="/opg">
                                <div class="card">
                                    <img src={opgnew} className="card-img-top mb-3" alt="opg" />
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkBlue" }} className="card-title mb-0">OPG</h5>
                                            <p style={{ color: "darkBlue" }} className="card-text">Learn More <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="13" height="13" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                            </svg></p>
                                        </center>
                                    </div>
                                </div>
                            </Link>
                        </div>

                    </div>

                </div>
            </div>
            <br />
            <br />
            <Imaging/>
            <br />
            <br />
         
        </div>
    )
}

export default Radiology