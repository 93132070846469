


import React, { useEffect, useState } from 'react'

import ReactWhatsapp from "react-whatsapp";
import { collection, getDoc, doc, } from 'firebase/firestore'
import { db } from '../../firebase'
import { Link, useParams } from 'react-router-dom'
import Navbar from '../inc/Navbar';

import alcoholpack from "../images/alchol p.jpg";

function DocConsult() {


    const userinfoRef = collection(db, "Appointments")
    const [generalp, setGeneralp] = useState()
    const params = useParams()
    useEffect(() => {
        getData()
    }, [])
    async function getData() {
        try {
            const generalpTemp = await getDoc(doc(db, 'GeneralPackage', params.docId))
            setGeneralp(generalpTemp.data())

        } catch (error) {
            console.log(error.message)
        }
    }

    return (
        <>
            <Navbar />
            <br />
            <br />
            <br />
            <br></br>
            <div className='backcmew'>
                <div className="album py-1">

                    <div className="container ">

                        <div className="row">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-2">
                                    <li style={{ color: "blue" }} className="breadcrumb-item"><Link style={{ color: "grey" }} to="/health-package" >Back</Link></li>

                                    <li className="breadcrumb-item active" style={{ color: "grey" }} aria-current="page">General Package</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-sm' >

                <div className='DocConsultant' style={{ margin: 0 }}>
                    {/* <div className='doc-div' style={{ display: "flex", justifyContent: "space-between"}}>

            <div className='DocConsultant' style={{ display: "block",margin:50 }}> */}
                    <div className="album py-3">

                        <div className="container">

                            <div className="row">
                                <div className="col-md-6 me-5">
                                    <h4 style={{ color: "grey" }} className="card-text mb-4"> {generalp && generalp.name} </h4>
                                    <p style={{ color: "grey" }} className="card-text mb-3"> <small>POSTED ON BY {generalp && generalp.post}  </small></p>
                                    <div className="card mb-4 box ">

                                        <div className="card-body">
                                            {/* <Image style={{ display: "block", width: 350, height: 250 }} src={doctor && doctor.docImg} rounded/> */}
                                            <div className="textcolor">

                                                <h5 className="card-text"> {generalp && generalp.name}</h5>
                                            </div>

                                            <hr />
                                            <p className='mb-0'><b>Pre Test Information:</b></p>
                                            <p className="card-text ">{generalp && generalp.pti}</p>
                                            <p className='mb-0'><b>Report Delivery:</b></p>
                                            <p style={{ color: "grey" }} className="card-text mb-3"><small>{generalp && generalp.delivery}</small></p>

                                            <div className="d-flex justify-content-between align-items-center">

                                                <a href='tel: 9332701212 ' className="buttoncall mb-2">Call Now</a>

                                                <ReactWhatsapp number="+91 9332701212" className="buttonwhatsapp mb-2" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>


                                            </div>
                                            <p> <center> <svg style={{ color: "maroon" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
                                                <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                                            </svg> Home Collection Available</center></p>





                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">

                                    <div className='card'>
                                        <img src={generalp && generalp.img2} className="card-img-top" alt={generalp && generalp.name}/>
                                        <div className="card-body">
                                            <p className="card-text ">{generalp && generalp.text1}</p>
                                            <p className="card-text ">{generalp && generalp.text2}</p>
                                            <h5 style={{ color: "purple" }} className="card-text mb-3">Package Inclusions</h5>


                                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingOne">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                            <div className="textcolor">
                                                                <p className="card-text ">{generalp && generalp.profile1}</p>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <p className="card-text ">{generalp && generalp.ptext1}</p>
                                                            <p className="card-text ">{generalp && generalp.ptext2}</p>
                                                            <p className="card-text ">{generalp && generalp.ptext3}</p>
                                                            <p className="card-text ">{generalp && generalp.ptext4}</p>
                                                            <p className="card-text ">{generalp && generalp.ptext5}</p>
                                                            <p className="card-text ">{generalp && generalp.ptext6}</p>
                                                            <p className="card-text ">{generalp && generalp.ptext7}</p>
                                                            <p className="card-text ">{generalp && generalp.ptext8}</p>
                                                            <p className="card-text ">{generalp && generalp.ptext9}</p>

                                                            <p className="card-text ">{generalp && generalp.ptext10}</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                            <div className="textcolor">
                                                                <p className="card-text ">{generalp && generalp.profile2}</p>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <p className="card-text ">{generalp && generalp.p2text1}</p>
                                                            <p className="card-text ">{generalp && generalp.p2text2}</p>
                                                            <p className="card-text ">{generalp && generalp.p2text3}</p>
                                                            <p className="card-text ">{generalp && generalp.p2text4}</p>
                                                            <p className="card-text ">{generalp && generalp.p2text5}</p>

                                                            <p className="card-text ">{generalp && generalp.p2text6}</p>
                                                            <p className="card-text ">{generalp && generalp.p2text7}</p>
                                                            <p className="card-text ">{generalp && generalp.p2text8}</p>
                                                            <p className="card-text ">{generalp && generalp.p2text9}</p>
                                                            <p className="card-text ">{generalp && generalp.p2text10}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingThree">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                            <div className="textcolor">
                                                                <p className="card-text ">{generalp && generalp.profile3}</p>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <p className="card-text ">{generalp && generalp.p3text1}</p>
                                                            <p className="card-text ">{generalp && generalp.p3text2}</p>
                                                            <p className="card-text ">{generalp && generalp.p3text3}</p>
                                                            <p className="card-text ">{generalp && generalp.p3text4}</p>
                                                            <p className="card-text ">{generalp && generalp.p3text5}</p>
                                                            <p className="card-text ">{generalp && generalp.p3text6}</p>
                                                            <p className="card-text ">{generalp && generalp.p3text7}</p>
                                                            <p className="card-text ">{generalp && generalp.p3text8}</p>
                                                            <p className="card-text ">{generalp && generalp.p3text9}</p>
                                                            <p className="card-text ">{generalp && generalp.p3text10}</p>

                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingFour">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                            <div className="textcolor">
                                                                <p className="card-text ">{generalp && generalp.profile4}</p>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <p className="card-text ">{generalp && generalp.p4text1}</p>
                                                            <p className="card-text ">{generalp && generalp.p4text2}</p>
                                                            <p className="card-text ">{generalp && generalp.p4text3}</p>
                                                            <p className="card-text ">{generalp && generalp.p4text4}</p>
                                                            <p className="card-text ">{generalp && generalp.p4text5}</p>

                                                            <p className="card-text ">{generalp && generalp.p4text6}</p>
                                                            <p className="card-text ">{generalp && generalp.p4text7}</p>
                                                            <p className="card-text ">{generalp && generalp.p4text8}</p>
                                                            <p className="card-text ">{generalp && generalp.p4text9}</p>
                                                            <p className="card-text ">{generalp && generalp.p4text10}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingFive">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                            <div className="textcolor">
                                                                <p className="card-text">{generalp && generalp.profile5}</p>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                  
                                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <p className="card-text ">{generalp && generalp.p5text1}</p>
                                                            <p className="card-text ">{generalp && generalp.p5text2}</p>
                                                            <p className="card-text ">{generalp && generalp.p5text3}</p>
                                                            <p className="card-text ">{generalp && generalp.p5text4}</p>
                                                            <p className="card-text ">{generalp && generalp.p5text5}</p>
                                                            <p className="card-text ">{generalp && generalp.p5text6}</p>
                                                            <p className="card-text ">{generalp && generalp.p5text7}</p>
                                                            <p className="card-text ">{generalp && generalp.p5text8}</p>
                                                            <p className="card-text ">{generalp && generalp.p5text9}</p>
                                                            <p className="card-text ">{generalp && generalp.p5text10}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>



                                            <h5 style={{color:"grey"}} className="card-text mt-3">       Frequently Asked Questions (FAQs)</h5>
{/*                                      
<p style={{color:"darkBlue"}} className='card-text '><b>Q1. What is Alcoholism?</b></p>
<p className="card-text ">{generalp && generalp.qus1}</p> */}

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default DocConsult