import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import { db } from '../../firebase'

import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";





function Imaging() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(2500);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [radiology, setRadiology] = useState([])
    const navigate = useNavigate()

    const radiologyCollectionRef = collection(db, "Radiology");
    useEffect(() => {

        const getRadiology = async () => {
            const data = await getDocs(radiologyCollectionRef);
            setRadiology(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getRadiology()
    }, [])
    return (

        <>






            {/* <h2 className="card-text mb-2" style={{paddingTop: "1rem", margin: "auto 0", color: "Gray", textAlign: "center"}}> 
<b> Search <span style={{color: "maroon", fontWeight: "bold", marginleft: '10px'}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={60}
    deleteSpeed={90}
    delaySpeed={1000}
    words={[ "Bala", "M. B. Dhar and Son Jewellers", "Best Jewellery Collections"]}
    />
</span>
</b></h2> */}
            <div className="album py-2">

                <div className="container">

                    <div className="row">

                        <div className="col-md-6">

                            <div className="card-body">
                                <input type="text" className="form-control" placeholder="Search" name="location"
                                    onChange={(e) => {
                                        setsearchTerm(e.target.value);
                                    }}
                                />
                                <div className="d-flex justify-content-between align-items-center">




                                </div>


                            </div>


                        </div>

                    </div>

                </div>
            </div>
            <div className="album py-4">

                <div className="container">

                    <div className="row">
                        {radiology.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase())


                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((questdiagnostics) => {
                            return (
                                <div className="col-md-4 mt-8" >
                                    <div className="card mb-2">

                                        <div className="card-body">
                                            <div className="textcolor">
                                                <h6 className="card-text"> <b>{questdiagnostics.name}</b></h6>
                                            </div>
                                            <p className='mb-0'><b>Pre Test Information:</b></p>
                                            <p className="card-text ">{questdiagnostics.pti}</p>
                                            <p className='mb-0'><b>Report Delivery:</b></p>
                                            <p style={{ color: "grey" }} className="card-text mb-3"><small>{questdiagnostics.delivery}</small></p>

                                            <div className="d-flex justify-content-between align-items-center">

                                                <a href='tel: 9332701212' className="buttoncall mb-3">Call Book</a>
                                                <ReactWhatsapp number="+91 9332701212" className="buttonwhatsapp mb-3" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                    {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={bala.length}
                /> */}

                </div>
            </div>

        </>

    );
}

export default Imaging;



