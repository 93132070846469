import React, { useState } from "react";

import { TimePickerComponent } from "@syncfusion/ej2-react-calendars"

import {
    ref,
    uploadBytes,
} from "firebase/storage";
import { storage } from '../firebase';
import { v4 } from "uuid";

import { Form, Alert, InputGroup, Button, ButtonGroup, Card } from "react-bootstrap";
import BookDataService from "../services/book.homevisit";




const AddHomevisit = () => {

    const [imageUpload, setImageUpload] = useState(null);


    const [selectedTime, setSelectedTime] = useState(null)



    const uploadFile = () => {
        if (imageUpload == null) return;
        const imageRef = ref(storage, `prescription/${imageUpload.name + v4()}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            alert("Prescription Uploaded Seccessful")
        });

    };









   
    return (
        <>


            <div className="album py-0">
                <div className="container">

                    <div className="row ">
                        <div className="col-md-6 ">

                         
                              
                                    <div className="textcolor">
                                        <h3 style={{color:"white"}} className="mt-0 mb-3 mt-3" ><b>Upload Prescription</b></h3>
                                    </div>
                               
                                <div className="card mb-4 box-shadow">


                                   

                                   
                          


                                        <input className="mb-3 mt-3"
                                            type="file"
                                            onChange={(event) => {
                                                setImageUpload(event.target.files[0]);
                                            }}
                                        />
                                        Upload Prescriptions
                                      

                                        {/* <div className='time-picker mt-3 mb-2'>
                    <TimePickerComponent onChange= {(e) => setSelectedTime(e.target.value)} placeholder='Select a time' min={minTime} max={maxTime} value ={ selectedTime } required>

                    </TimePickerComponent>
                </div>  */}

                                        <br />
                                        <div className="d-grid gap-2 mb-2">
                                            <button onClick={uploadFile} className="uploadbutton">  Submit </button>
                                        </div>



                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>



        </>


    )
}
export default AddHomevisit;