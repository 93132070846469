import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'



import ReactWhatsapp from "react-whatsapp";


function AlcoholPackage() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(45);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [alcoholpackage, setAlcoholpackage] = useState([])
    const navigate = useNavigate()

    const alcoholpackageCollectionRef = collection(db, "AlcoholImpactPackage");
    useEffect(() => {

        const getAlcoholpackage = async () => {
            const data = await getDocs(alcoholpackageCollectionRef);
            setAlcoholpackage(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getAlcoholpackage()
    }, [])
    return (

        <>
            <br />



            {/* <h1 className="card-text mb-2" style={{paddingTop: "1rem", margin: "auto 0", color: "Gray", textAlign: "center"}}> 
<b> Search <span style={{color: "purple", fontWeight: "bold", marginleft: '10px'}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={90}
    deleteSpeed={50}
    delaySpeed={1000}
    words={[ " Health Website", "Magazine Website", "News Media Website", "Photographer & Studio Website", "Education Website", "Jewellers Website", "Personal Website ", "Any Shop Website", "Tax Company"]}
    />
</span>
</b></h1> */}
        
            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        {alcoholpackage.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase())





                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((alcoholpackage) => {
                            return (

                                <div className="col-md-4" >
                                    <div className="card cardnew mb-3">

                                        <p onClick={() => { navigate(`/besthealth/${alcoholpackage.id}`) }} type="button" >

                                            <div className="card-body">
                                                <div className="textcolor">
                                                    <h6 className="card-text"> <b>{alcoholpackage.name}</b></h6>
                                                </div>

                                                <h5 className="card-text mb-3">{alcoholpackage.title}</h5>


                                                <p className='mb-0'><b>Pre Test Information:</b></p>
                                                <p className="card-text ">{alcoholpackage.pti}</p>
                                                <p className='mb-0'><b>Report Delivery:</b></p>
                                                <p style={{ color: "grey" }} className="card-text mb-2"><small>{alcoholpackage.delivery}</small></p>
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                            
                                                <p style={{color:"grey"}}>M.R.P.: <del>{alcoholpackage.mrp}</del></p>
                                                <button className='offbutton'>{alcoholpackage.off}% OFF</button>
                                                </div>
                                                <h5 style={{color:"darkBlue"}}> ₹ <b>{alcoholpackage.price}</b> </h5>

                                                <div className="d-flex justify-content-between align-items-center">

                                                    <p className="button button1 mb-2">View Details</p>

                                                    <ReactWhatsapp number="+91 9332701212" className="button button1 mb-2" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>


                                                </div>
                                                <p className='mb-0'> <center> <svg style={{ color: "maroon" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
                                                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                                                </svg> Home Collection Available</center></p>

                                            </div>
                                        </p>
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>

        </>

    );
}

export default AlcoholPackage;