import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import { db } from '../../firebase'
import Navbar from '../inc/Navbar';
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";

import ScrollToTop from "react-scroll-to-top";





function Findatest() {
  const [setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(2500);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [labtest, setLabtest] = useState([])
  const navigate = useNavigate()

  const labtestCollectionRef = collection(db, "Labtest");
  useEffect(() => {

    const getLabtest = async () => {
      const data = await getDocs(labtestCollectionRef);
      setLabtest(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getLabtest()
  }, [])
  return (

    <>
      <Navbar />

     
     <br/>
      <br />
      <ScrollToTop smooth top="100" color="light" />

      <br />
      <br />
    
        <div className='diagnostics'>
          <div className="album py-1 mt-2">

            <div className="container">

              <div className="row">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li style={{ color: "grey" }} className="breadcrumb-item"><Link style={{ color: "grey" }} to="/" >  Home</Link></li>

                    <li style={{ color: "grey" }} className="breadcrumb-item"><Link style={{ color: "grey" }} to="/contact-us" href="#">Contact</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Find a Test</li>

                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

       
          <div className="album py-3">

            <div className="container">

              <div className="row">

                <div className="col-md-6">

                  <div className="card-body">
                    <input type="text" className="form-control" placeholder="Search" name="location"
                      onChange={(e) => {
                        setsearchTerm(e.target.value);
                      }}
                    />
                    <div className="d-flex justify-content-between align-items-center">




                    </div>


                  </div>


                </div>

              </div>

            </div>
          </div>
       
    
      <div className="album py-4">

        <div className="container">

          <div className="row">
            {labtest.filter((val) => {
              if (setsearchTerm === "") {
                return val;
              } else if (
                val.name.toLowerCase().includes(searchTerm.toLowerCase())


              ) {
                return val;
              }
            }).slice(pagination.start, pagination.end).map((questdiagnostics) => {
              return (
                <div className="col-md-4 mt-8" >
                  <div className="card mb-2">
                    {/* <img  className="card-img-top" src={questdiagnostics.Img}  alt="labtest"/> */}
                    <div className="card-body">
                      <div className="textcolor">
                        <h6 className="card-text"> <b>{questdiagnostics.name}</b></h6>
                      </div>
                      <p className='mb-0'><b>Pre Test Information:</b></p>
                      <p className="card-text ">{questdiagnostics.pti}</p>
                      <p className='mb-0'><b>Report Delivery:</b></p>
                      <p style={{ color: "grey" }} className="card-text mb-2"><small>{questdiagnostics.delivery}</small></p>
                      <div className="d-flex justify-content-between align-items-center mb-0">
                                            
                                            <p style={{color:"grey"}}>M.R.P.: <del>{questdiagnostics.mrp}</del></p>
                                            <button className='offbutton mb-1'>{questdiagnostics.off}% OFF</button>
                                            </div>
                                            <h6 className='mb-3' style={{color:"darkBlue"}}> ₹ {questdiagnostics.price}</h6>
                      <div className="d-flex justify-content-between align-items-center">

                        <a href='tel: 9332701212' className="buttoncall mb-3">Call Book</a>
                        <ReactWhatsapp number="+91 9332701212" className="buttonwhatsapp mb-3" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>

                      </div>

                    </div>

                  </div>

                </div>

              )
            })}
          </div>
          {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={bala.length}
                /> */}

        </div>
      </div>

    </>

  );
}

export default Findatest;



