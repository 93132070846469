import React from 'react'
import AddFeedbacks from '../AddFeedbacks'

function Feedback() {
  return (
    <div>
      
         
      <div className='album py-1'>
        <div className="container">

          <div className="row">
            <h6 className='mb-3' style={{color:"grey"}}>Your feedback is about our:</h6>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><small>Online Feedback</small></button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"><small>In-Diagnostics Test experience</small></button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false"><small>Help Line</small></button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <br/>
                <AddFeedbacks/>
              </div>
              <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <br/>
                <AddFeedbacks/>
              </div>
              <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <br/>
                <p className='mt-2 mb-3'>Any query please cantact : - </p>
                <a style={{color:"grey"}} href='tel: 9332701212'>
                  <p className='mb-1'>+91 93327 01212 / <a style={{color:"grey"}} href="tel: +91 9332701215"> 15</a></p>
                  </a>
                <a style={{color:"grey"}} href='mailto: service@questdiagnostics.org.in'>service@questdiagnostics.org.in</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  )
}

export default Feedback