



import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyAAa5ty9OSizphFFFT1yVOuDGyCV_IOGA0",
    authDomain: "quest-diagnostics-68128.firebaseapp.com",
    projectId: "quest-diagnostics-68128",
    storageBucket: "quest-diagnostics-68128.appspot.com",
    messagingSenderId: "882196148069",
    appId: "1:882196148069:web:dc8c1c415770d305477b8c",
    measurementId: "G-B8Z2S9XSXD"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;



