import React from 'react'
import Navbar from '../inc/Navbar'

function Terms() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="album py-2">
                <div className="container">
        <center>
            <h2 style={{color:"grey"}}> Terms & Conditions </h2>
            <hr/>
        </center>
        </div>
        </div>
        <div className="album py-2">
                <div className="container">
                    <h3 className='mb-3' >CONDITIONS OF LABROTORY TESTING & REPORTING</h3>
                   
                    <p className='textjustify mb-2' >1. The results relate only to the samples recieved & tested in our labrotory</p>
                    <p className='textjustify mb-2' >2. It is presumed that the test sample belong to the patient named or identified in the Test 
                        Requisite Form, for the purpose of reporting of the same parameters.
                    </p>
                    <p className='textjustify mb-2'>3. Repeat sample may be requested for confirming the result due to various technical reasons. </p>
                    <p className='mb-2'>4. The technical note give below a test heading is a general comment on the test.</p>
                    <p className='mb-2'> 5. Test Run Dates/Turn Around Time may vary on the basis of availability of test kits.</p>
                    <p className='textjustify'>6. Reference range of parameters are machine and kit specific. It may vary from time to time due to multiple factors like change in kits, change of machine, change in technology etc.</p>
                    <p>7. Labrotory results should be correlated with clinical information to determine a final diagnosis. </p>
                    <p>8. Partial representation of the report is not permitted, as it may lead to misinterpretation.</p>
                    <p>9. The Courts/Forum at Krishnanagar shall have exclusive Jurisdiction in all dispute/claims concerning the tests & results of tests</p>
                    <p>10. In case of queries or unexpected test results please call at helpline. Post proper investigation repeat analysis may be carried out.</p>
                    <p> 11. Any query from the referring doctor pertaining to this report should be directed to the chief pathologist.</p>
                    <p className='textjustify'>12. All modern machines/procedure have its limitations. If there is a variance clinically, this examination may be repeated or re-evaluated by other investigations.</p>
                    
                    </div>
                    </div>
    </div>
  )
}

export default Terms