import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import mri from "../images/mri.jpg";
import ScrollToTop from "react-scroll-to-top";
function Mri() {
    return (
        <div>
            <Navbar />

            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="light"/>
            <div className='diagnostics'>
                <div className="album py-0 mt-3">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/Radiology" style={{ color: "darkblue" }} href="#">Back</Link></li>

                                <li className="breadcrumb-item active" aria-current="page">MRI <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg> </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-2">
                <div className="container">

                    <div className="row">
                        <div className="col-md-9">


                            <div className="card-body">
                                <h2 className="card-title mb-3">Diagnostic Service - MRI</h2>
                                <br/>
                                <p className="card-text mb-3">Magnetic resonance imaging uses high magnetic field and radio waves to creat images of the tissues, organs and structures inside the body.</p>
                               
                                <a href="tel: +91 9332701212">
                                <p style={{color:"darkBlue"}} className="mb-2" > Helpline:   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                                </svg> +91 9332701212
                                </p> </a>
                                <br/>
                                <h2>OUR FACILITY:</h2>
                            
                               
                                <p>Not Available</p>
                               


                            </div>

                        </div>
                        <div className="col-md-3">
                     
                        <img src={mri} className="card-img-top" alt="Mri"/>

                            
                        </div>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    )
}

export default Mri