import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import { db } from '../../firebase'

import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import Navbar from '../inc/Navbar';
import ScrollToTop from "react-scroll-to-top";




function Neurology() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(2500);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [neurology, setNeurology] = useState([])
    const navigate = useNavigate()

    const neurologyCollectionRef = collection(db, "Neurology");
    useEffect(() => {

        const getNeurology = async () => {
            const data = await getDocs(neurologyCollectionRef);
            setNeurology(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getNeurology()
    }, [])
    return (

        <>


            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br></br>


            <ScrollToTop smooth top="100" color="light" />
            <div className='fixed-top'>

                <br />
                <br />
                <br />
                <br />



                <div className='diagnostics'>
                    <div className="album py-0 mt-2">
                        <div className="container">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>

                                    <li className="breadcrumb-item active" aria-current="page">Neurology <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                    </svg> </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

                <div className="questcolor">

                    <div className="album py-4">

                        <div className="container">

                            <div className="row">

                                <div className="col-md-6">

                                    <div className="card-body">
                                        <input type="text" className="form-control" placeholder="Search" name="location"
                                            onChange={(e) => {
                                                setsearchTerm(e.target.value);
                                            }}
                                        />
                                        <div className="d-flex justify-content-between align-items-center">




                                        </div>


                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        {neurology.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase())


                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((questdiagnostics) => {
                            return (
                                <div className="col-md-4 mt-8" >
                                    <div className="card mb-2">

                                        <div className="card-body">
                                            <div className="textcolor">
                                                <h6 className="card-text"> <b>{questdiagnostics.name}</b></h6>
                                            </div>
                                            <p className='mb-0'><b>Pre Test Information:</b></p>
                                            <p className="card-text ">{questdiagnostics.pti}</p>
                                            <p className='mb-0'><b>Report Delivery:</b></p>
                                            <p style={{ color: "grey" }} className="card-text mb-3"><small>{questdiagnostics.delivery}</small></p>

                                            <div className="d-flex justify-content-between align-items-center">

                                                <a href='tel: 9332701212' className="buttoncall mb-3">Call Book</a>
                                                <ReactWhatsapp number="+91 9332701212" className="buttonwhatsapp mb-3" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                    {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={bala.length}
                /> */}

                </div>
            </div>

        </>

    );
}

export default Neurology;



