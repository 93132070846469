import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'



import ReactWhatsapp from "react-whatsapp";


function GeneralP() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(45);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [generalp, setGeneralp] = useState([])
    const navigate = useNavigate()

    const generalpCollectionRef = collection(db, "GeneralPackage");
    useEffect(() => {

        const getGeneralp = async () => {
            const data = await getDocs(generalpCollectionRef);
            setGeneralp(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getGeneralp()
    }, [])
    return (

        <>
            <br />

            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        {generalp.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase())





                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((generalp) => {
                            return (

                                <div className="col-md-4 mt-2" >
                                    <div className="card cardnew mb-3">

                                        <p onClick={() => { navigate(`/healthpackage/${generalp.id}`) }} type="button" >

                                            <div className="card-body">
                                                <div className="textcolor">
                                                    <h6 className="card-text"> <b>{generalp.name}</b></h6>
                                                </div>

                                                <h5 className="card-text mb-3">{generalp.title}</h5>


                                                <p className='mb-0'><b>Pre Test Information:</b></p>
                                                <p className="card-text ">{generalp.pti}</p>
                                                <p className='mb-0'><b>Report Delivery:</b></p>
                                                <p style={{ color: "grey" }} className="card-text mb-3"><small>{generalp.delivery}</small></p>
                                                <div className="d-flex justify-content-between align-items-center">
                                            
                                            <p style={{color:"grey"}}>M.R.P.: <del>{generalp.mrp}</del></p>
                                            <button className='offbutton'>{generalp.off}% OFF</button>
                                            </div>
                                            <h6 style={{color:"darkBlue"}}> <del>₹ {generalp.price}</del></h6>

                                                <div className="d-flex justify-content-between align-items-center">

                                                    <p className="button button1 mb-2">View Details</p>

                                                    <ReactWhatsapp number="+91 9332701212" className="button button1 mb-2" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>


                                                </div>
                                                <p className='mb-0'> <center> <svg style={{ color: "maroon" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
                                                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                                                </svg> Home Collection Available</center></p>

                                            </div>
                                        </p>
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>

        </>

    );
}

export default GeneralP;