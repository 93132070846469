import React, { useState } from "react";
import { Form, Alert, InputGroup, Button, Card } from "react-bootstrap";
import BookDataCareer from "../services/book.homesample";



const AddHomesample = () => {
    const [title, setTitle] = useState("");
    const [enquiry, setEnquiry] = useState("");
    const [city, setCity] = useState("");

    const [mobile, setMobile] = useState("");
    const [pin, setPin] = useState("");
    const [status, setStatus] = useState("Available");
    const [flag, setFlag] = useState(true);
    const [massage, setMassage] = useState({ error: false, msg: "" });



    const handleSubmit = async (e) => {
        e.preventDefault();
        setMassage("");
        if (title === "" || mobile === "" || pin === "" || city === "" || enquiry === "") {
            setMassage({ error: true, msg: "All fields are mandatory!" });
            return;
        }
        const newBook = {
            title,
            mobile,
            pin,

            city,
            enquiry,
            status
        }
        console.log(newBook);

        try {
            await BookDataCareer.addBooks(newBook);
            setMassage({ error: false, msg: "Submited Successfuly!" });
        } catch (err) {
            setMassage({ error: true, msg: err.massage });
        }

        setTitle("");
        setMobile("");
        setPin("");

        setCity("");
        setEnquiry("");
    };



    return (
        <>






            {massage?.msg && (<Alert variant={massage?.error ? "danger" : "success"}
                dismissible
                onClose={() => setMassage("")}
            >
                {""}
                {massage?.msg}
            </Alert>
            )}

            <Form onSubmit={handleSubmit} >
                <div className="album py-2">
                    <div className="container">
                        <div className="row ">

                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookTitle">
                                    <InputGroup>
                                        <Form.Control type="text"
                                            placeholder="Name"

                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>


                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookMobile">

                                    <Form.Control type="text"
                                        placeholder="Mobile"
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookPin">
                                    <Form.Control type="text"
                                        placeholder="Pin"
                                        value={pin}
                                        onChange={(e) => setPin(e.target.value)}
                                    />

                                </Form.Group>

                            </div>


                            <div className="col-md-6">

                                <Form.Group className="mb-3" controlId="formBookCity">

                                    <Form.Control type="text"
                                        placeholder="City"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />

                                </Form.Group>
                            </div>


                            <div className="col-md-12">
                                <Form.Group className="mb-3" controlId="formBookEnquiry">


                                <Form.Control as="textarea" row={3}
                    placeholder="Enter test name"
                    value={  enquiry }
                    onChange={(e) => setEnquiry(e.target.value)}
                    />

                                </Form.Group>

                            </div>

                            {/* <Form.Select className="mb-3" controlId="formBookJobcategary">
                
              

        
      
      <option value={ jobcategary} onChange={(e) => setJobcategary(e.target.value)}>One</option>
      <option value={ jobcategary} onChange={(e) => setJobcategary(e.target.value)}>Two</option>
      <option value={ jobcategary} onChange={(e) => setJobcategary(e.target.value)}>Three </option>
      
      
      
     
     
     
    
        </Form.Select> */}


                            {/* <Form.Group className="mb-3" controlId="formBookJobcategary">
          <Form.Label>Disabled select menu</Form.Label>
          <Form.Select >
            <option>Animation</option>
            <option>Game 2D </option>
            <option>VFX</option>
          </Form.Select>
             </Form.Group> */}

                            {/* <ButtonGroup aria-lable="Basic example" className="mb-3">
                    <Button 
                    disabled={flag} variant="success" 
                    onClick={(e) => {
                    setStatus("Available");
                    setFlag(true);
                    }}
                    >
                        Available
                        </Button>
                        <Button variant="danger" 
                        onClick={(e) => {
                        setStatus("Not Available");
                        setFlag(false);
                        }}
                        >Not Available</Button>
        
                </ButtonGroup> */}







                        </div>

                        <button className="homesample" type="Submit">

                            Submit

                        </button>
                    </div>
                </div>

            </Form>






        </>


    )
}
export default AddHomesample;