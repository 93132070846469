import React from 'react'
import {Link} from "react-router-dom";
import "./Navbar.css";
import Logo from "../images/queestlogo.jpg";


import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdb-react-ui-kit';


function Navbar() {
  return (
    <div>
      <div className='fixed-top'>
      <div className="questcolor">
  <div className="album py-1">
        
        <div className="container">

            <div className="row ">
       
            <div className="col-md-6" >
            <div className="d-flex justify-content-between align-items-center">
              
           <a style={{color:"white"}} className="" href="tel: +91 9332701212"> +91 9332 70 1212 / <a style={{color:"white"}} href="tel: +91 9332701215"> 15 Touch <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-index-thumb" viewBox="0 0 16 16">
  <path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025"/>
</svg></a> </a>

                     
                    <a target="_blank"  rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=61558277640447">           
                    <svg xmlns="http://www.w3.org/2000/svg" style={{color:"white"}} width="15" height="15" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
</svg> <small className='mt-1' style={{color:"white"}} >Facebook</small>
            </a> 
          

            
                              
                        
                            </div>
                               
                            </div>
                          
                            <div className="d-flex justify-content-between align-items-center">
                           


                
                         
                            </div>
                            </div>
                            </div>
                            </div>
 </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light shadow ">
  <div className="container-fluid ">
    <Link to="/" style={{color:"maroon"}} >
      <img src={Logo} height="44px" className='me-0' weight="95px" alt="logo"/>
   
    </Link>
  
      <Link to="/"  className="textcolor">
    <h5 className=''> Quest Diagnostics</h5>
    </Link>
   
   
    
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active button21" aria-current="page" href="#"><b>HOME</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/" className="nav-link button21" href="#"><b>ABOUT US</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/labtest" className="nav-link button21" href="#"><b>FIND A TEST</b></Link>
        </li>
     
        <li className="nav-item">
          <Link to="/health-package" className="nav-link button21" href="#"><b>HEALTH PACKAGE</b></Link>
        </li>
           {/* <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle button21" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Health Check Package
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/Radiology" className="dropdown-item button21" href="#">Alcohol Impact</Link></li>
            <li><Link to="/" className="dropdown-item button21"  href="#">Bone Health</Link></li>
            <li><Link to="/Cardiology" className="dropdown-item button21" href="#">Cancer</Link></li>
            <li><Link to="/Neurology" className="dropdown-item button21" href="#">Cardiovascular Diseases</Link></li>

            <li><Link to="/" className="dropdown-item button21" button21 href="#">Depression</Link></li>
            <li><Link to="/find-doctor" className="dropdown-item" href="#">Diabetes</Link></li>
       
          </ul>
        </li> */}
     
        <li className="nav-item">
          <Link to="/find-doctor" className="nav-link button21" href="#"><b>FIND DOCTOR</b></Link>
        </li>
        
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle button21" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           <b> SERVICES</b>
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/Radiology" className="dropdown-item button21" href="#">Radiology / Imaging</Link></li>
            <li><Link to="/" className="dropdown-item button21"  href="#">Pathology</Link></li>
            <li><Link to="/Cardiology" className="dropdown-item button21" href="#">Cardiology</Link></li>
            <li><Link to="/Neurology" className="dropdown-item button21" href="#">Neurology</Link></li>

            <li><Link to="/" className="dropdown-item button21" button21 href="#">Gasteornlogy</Link></li>
            <li><Link to="/find-doctor" className="dropdown-item" href="#">Polyclinic</Link></li>
       
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/contact-us" className="nav-link button21" href="#"><b>CONTACT</b></Link>
        </li>
      </ul>

<a href='tel: +91 9332701215'>
        <button className="navbarcall" type="submit">+91 9332 70 1215</button>
        </a>
     
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Navbar