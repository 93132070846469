import React from 'react'
import Navbar from '../inc/Navbar'
import Feedback from './Feedback'

function Feedbackpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Feedback/>
    </div>
  )
}

export default Feedbackpage