import React from 'react'
import  Navbar  from '../inc/Navbar';
import AddHomesample from '../AddHomesample';
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";

function Sample() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="light"/>
        <div className='diagnostics'>
                <div className="album py-0 mt-3">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>

                                <li className="breadcrumb-item active" aria-current="page">Contact Us <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg> </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        <div className="album py-3">
        <div className="container">
            <div className='diagnosticskrsihnagar'>
            <h2 className='mb-3'>Home Collection Services</h2>
            <h6>TO BOOK YOUR HOME COLLECTION, PLEASE FILL UP THE FORM BELOW:</h6>
            </div>
            </div>
            <br/>
            <AddHomesample/>
            <br/>
            </div>

    </div>
  )
}

export default Sample